@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Inter-Regular';
    font-display: fallback;
    font-weight: 400;
    src: url('assets/fonts/Inter-Regular.woff2') format('woff2');
  }

  @font-face {
    font-family: 'Inter-Medium';
    font-display: fallback;
    font-weight: 500;
    src: url('assets/fonts/Inter-Medium.woff2') format('woff2');
  }
  @font-face {
    font-family: 'Inter-Bold';
    font-display: fallback;
    font-weight: 600;
    src: url('assets/fonts/Inter-Bold.woff2') format('woff2');
  }
  /*typography for desktop and mobile*/
  .h1-b {
    @apply text-[30px] lg:text-[40px] xxl:text-[50px] font-[Inter-Bold];
  }
  .h1-l {
    @apply text-[30px] lg:text-[40px] xxl:text-[50px] font-[Inter-Regular];
  }
  .h2-b {
    @apply text-[36px] lg:text-[48px] font-[Inter-Bold];
  }
  .h2-l {
    @apply text-[36px] lg:text-[48px] font-[Inter-Regular];
  }
  .h3-b {
    @apply text-[28px] lg:text-[38px] font-[Inter-Bold];
  }
  .h3-l {
    @apply text-[28px] lg:text-[38px] font-[Inter-Regular];
  }
  .title-b {
    @apply text-[24px] lg:text-[32px] font-[Inter-Bold];
  }
  .title-l {
    @apply text-[24px] lg:text-[32px] font-[Inter-Regular];
  }
  .subtitle-b {
    @apply text-[18px] lg:text-[24px] font-[Inter-Bold];
  }
  .subtitle-l {
    @apply text-[18px] lg:text-[24px] font-[Inter-Regular];
  }
  .overline-b {
    @apply text-[16px] lg:text-[20px] font-[Inter-Bold];
  }
  .overline-l {
    @apply text-[16px] lg:text-[20px] font-[Inter-Regular];
  }
  .body-b {
    @apply text-[16px] font-[Inter-Bold];
  }
  .body-l {
    @apply text-[16px] font-[Inter-Regular];
  }
  .para-l {
    @apply text-[14px] font-[Inter-Regular];
  }
  .para-b {
    @apply text-[14px] font-[Inter-Bold];
  }

  .label-b {
    @apply text-[12px] font-[Inter-Bold];
  }
  .label-l {
    @apply text-[12px] font-[Inter-Regular];
  }

  .base-scrollbar {
    @apply scrollbar-thin scrollbar-thumb-[#EEEAEA] scrollbar-track-[#f1f1f1] overflow-auto;
  }
  .base-scrollbar {
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
    }
  }
}

// body {
//   overflow: hidden;
// }
audio {
  &::-webkit-media-controls-panel {
    background-color: transparent;
  }
  &::-webkit-media-controls-enclosure {
    background-color: transparent;
  }
}
.arrow {
  @apply bg-white-light;
  &:after {
    content: '';
    display: block;
    border-width: 10px;
    border-style: solid;
    border-color: #fff transparent transparent;
    position: absolute;
    bottom: -20px;
    left: 50%;
  }
  &-left:after {
    left: 0;
    right: auto;
    border-left-color: #fff;
  }
  &-right:after {
    border-left-color: transparent;
    border-right-color: #fff;
    left: auto;
    right: 0;
  }
}
.transcript {
  display: grid;
  grid-template-columns: repeat(16, 40px);
  grid-template-rows: repeat(5, 45px);
  justify-content: center;
  &-bg {
    grid-column: 2/11;
    grid-row: 1/-1;
  }
  &-speaker {
    &-one {
      grid-column: 3/10;
      grid-row: 3/7;
    }
    &-two {
      grid-column: 13/15;
      grid-row: 3/7;
    }
    &-table {
      grid-column: 11/13;
      grid-row: 5/7;
      z-index: 1;
    }
    &-table-shadow {
      grid-column: 11/13;
      grid-row: 6/7;
      z-index: -11;
    }
  }
}

.table-last-cell-center {
  td,
  th {
    &:last-child {
      @apply text-center;
    }
  }
}
.login-page {
  .MuiCircularProgress-root {
    width: 30px !important;
    height: 30px !important;
  }
}

.mask-img {
  img {
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    height: auto;
    width: 100%;
    max-width: 100%;
    -webkit-mask-size: auto auto;
    -webkit-mask-image: url(./assets/images/LandingPage/blob2.svg);
    mask-image: url(./assets/images/LandingPage/blob2.svg);
  }
}

.lp-accordin {
  &.Mui-expanded {
    margin: 0 !important;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px;
  }
  .MuiAccordionSummary-content {
    margin-block: 20px;
  }
}
.alternate-image-section {
  figure {
    &:nth-child(even) {
      img {
        @apply order-1 md:order-2;
      }
      figcaption {
        @apply order-2 md:order-1;
      }
    }
  }
}
.scroll-hidden {
  overflow: hidden;
}

.testimonials-slider {
  ul {
    @apply w-full;
    .react-multi-carousel-item {
      @apply py-20;
    }
  }
}
.mobile-menu {
  transition: all 0.5s ease-in-out;
  transform: translateY(-120%);
  &.menu-open {
    transform: translateY(0);
  }
}

.lp-brand-slider-dots {
  .react-multi-carousel-dot--active button {
    @apply bg-blue-dark;
  }
  .react-multi-carousel-dot button {
    @apply border-blue-dark;
  }
}

//  hero banner animation css
.img-blk {
  @apply hidden md:block absolute opacity-0;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;
}
.img-one {
  animation-name: oneAnim;
  bottom: 50px;
  left: -60px;
  @screen lg {
    bottom: 0;
    left: 0;
  }
}
.img-two {
  bottom: 100px;
  left: -45px;
  animation-name: twoAnim;
  @screen lg {
    bottom: 80px;
    left: -50px;
  }
}
.img-three {
  bottom: 170px;
  left: -10px;
  animation-name: threeAnim;
  @screen lg {
    bottom: 140px;
    left: -80px;
  }
}
.img-four {
  bottom: 240px;
  left: 50px;
  animation-name: fourAnim;
  @screen lg {
    bottom: 310px;
    left: 60px;
  }
}
.img-five {
  bottom: 260px;
  left: 135px;
  @screen lg {
    bottom: 330px;
    left: 190px;
  }
  animation-name: fiveAnim;
}
.img-six {
  bottom: 230px;
  left: 170px;
  animation-name: sixAnim;
  @screen lg {
    bottom: 280px;
    left: 270px;
  }
}
.img-seven {
  bottom: 185px;
  left: 220px;
  animation-name: sevenAnim;
  @screen lg {
    bottom: 140px;
    left: 320px;
  }
}

@keyframes oneAnim {
  100% {
    bottom: 90px;
    left: -70px;
    opacity: 1;
  }
}
@keyframes twoAnim {
  100% {
    bottom: 140px;
    left: -60px;
    opacity: 1;
  }
}
@keyframes threeAnim {
  100% {
    bottom: 205px;
    left: -15px;
    opacity: 1;
  }
}
@keyframes fourAnim {
  100% {
    bottom: 270px;
    left: 80px;
    opacity: 1;
  }
}
@keyframes fiveAnim {
  100% {
    bottom: 230px;
    left: 185px;
    opacity: 1;
  }
}
@keyframes sixAnim {
  100% {
    bottom: 175px;
    left: 220px;
    opacity: 1;
  }
}
@keyframes sevenAnim {
  100% {
    bottom: 120px;
    left: 250px;
    opacity: 1;
  }
}

@media screen and (min-width: 1025px) {
  @keyframes oneAnim {
    100% {
      bottom: 90px;
      left: -70px;
      opacity: 1;
    }
  }

  @keyframes twoAnim {
    100% {
      bottom: 160px;
      left: -60px;
      opacity: 1;
    }
  }

  @keyframes threeAnim {
    100% {
      bottom: 230px;
      left: -25px;
      opacity: 1;
    }
  }

  @keyframes fourAnim {
    100% {
      bottom: 310px;
      left: 110px;
      opacity: 1;
    }
  }

  @keyframes fiveAnim {
    100% {
      bottom: 260px;
      left: 230px;
      opacity: 1;
    }
  }

  @keyframes sixAnim {
    100% {
      bottom: 195px;
      left: 260px;
      opacity: 1;
    }
  }

  @keyframes sevenAnim {
    100% {
      bottom: 100px;
      left: 280px;
      opacity: 1;
    }
  }
}

@media screen and (min-width: 1281px) {
  @keyframes oneAnim {
    100% {
      bottom: 60px;
      left: -90px;
      opacity: 1;
    }
  }
  @keyframes twoAnim {
    100% {
      bottom: 150px;
      left: -80px;
      opacity: 1;
    }
  }
  @keyframes threeAnim {
    100% {
      bottom: 240px;
      left: -70px;
      opacity: 1;
    }
  }
  @keyframes fourAnim {
    100% {
      bottom: 340px;
      left: 110px;
      opacity: 1;
    }
  }
  @keyframes fiveAnim {
    100% {
      bottom: 290px;
      left: 250px;
      opacity: 1;
    }
  }
  @keyframes sixAnim {
    100% {
      bottom: 220px;
      left: 280px;
      opacity: 1;
    }
  }
  @keyframes sevenAnim {
    100% {
      bottom: 100px;
      left: 330px;
      opacity: 1;
    }
  }
}
